.nominateBox {
  width: 100% auto;
  height: 100%;
  text-align: center;
  background-color: #4479d4;
  padding: 60px 150px;
}

.nominateBox .nominateBox__title {
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
}

.nominateBox .nominateBox__desc {
  color: #ffffff;
  font-family: 'Work Sans';
  font-size: 20px;
  line-height: 35px;
}

.nominateBox .nominateBox__btn {
  background-color: #6ac0df;
  border-color: #6ac0df;
  color: #ffffff;
  font-family: 'Work Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  padding: 14px 17px;
}

.nominateBox .nominateBox__btn:hover {
  background-color: rgba(106, 192, 223, 0.8) !important;
  border-color: rgba(106, 192, 223, 0.8) !important;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .nominateBox {
    padding: 80px 17px;
  }

  .nominateBox .nominateBox__btn {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.71px;
    line-height: 23px;
    text-align: center;
    padding: 12px 15px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

.speakers {
  text-align: center;
  padding-top: 100px;
}

.speakers__title {
  color: #474c55;
  font-family: 'Work Sans';
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 0;
}

.speakers__subtitle {
  color: #474c55;
  font-family: 'Work Sans';
  font-size: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}

.speakers .speakers__btn {
  color: #4479d4;
  border-color: #4479d4;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  padding: 0;
  height: 47px;
  width: 208px;
  text-align: center;
  text-transform: uppercase;
}

.speakers .speakers__btn:hover {
  color: #ffffff;
  border-color: #ffffff;
  background-color: #4479d4;
  font-family: 'Poppins';
  font-weight: 600;
}

.speakers .speakers__imgContainer {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  background-color: #dae0e8;
}

.speakers .speakers__imgContainer:hover {
  background-color: transparent;
  z-index: 0;
}

.speakers .speakers__imgContainer:after {
  content: '';
  background-color: rgba(68, 121, 212, 0.1);
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 0;
}

.speakers .speakers__imgContainer img {
  opacity: 0.4;
  filter: saturate(0);
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  transition: 0.2s;
}

.speakers .speakers__imgContainer:hover img {
  opacity: 1;
  filter: unset;
}

.speakers .speakers__imgContainer .speakers__social {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.speakers .speakers__imgContainer .speakers__social > .social__hide {
  display: none;
}

.speakers .speakers__imgContainer:hover > .speakers__social {
  display: block;
}

.speakers .speakers__imgContainer > .speakers__social > div > a {
  color: #fff;
  font-size: 20px;
  padding: 4px 10px;
  display: block;
  margin: 10px 8px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
}

.speakers .speakers__imgContainer > .speakers__social > div > a:hover {
  background-color: rgba(145, 145, 145, 0.5);
}

.speakers .speakers__imgContainer:hover + .speakers__name {
  color: #4479d4;
}

.speakers .speakers__name {
  color: #474c55;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.speakers .speakers__speakerTitle {
  color: #474c55;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.speakers .speakers__moreSpeakersText {
  color: #9098a5;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.speakers .speakers__moreSpeakersBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7fc;
  height: 79%;
}

.normal.no-preview.no-category .article-card .card-title-div {
  height: auto;
}

.navbar-nav {
  flex-direction: row;
}

.header .event-subtitle {
  color: #6ac0df;
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 35px;
  text-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.12);
}
/* stef */
#wsNavbar .ws-predictive-search {
  display: none;
}

.sponsor-bg {
  background: url('./../../assets/sponsor-bg.png');
  width: 100%;
  background-position: 15% 0%;
  background-size: 35% auto;
  background-repeat: no-repeat;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 100px;
  background-color: #fff;
  overflow-x: hidden;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.sponsor-bg .sponsor-bg__desc {
  text-align: center;
}

.sponsor-bg .sponsor-bg__desc p {
  margin: auto;
  display: block;
  max-width: 700px;
}

.sponsor-bg .sponsor-bg__images {
  text-align: center;
  margin: auto;
}

.sponsor-bg .sponsor-bg__images > div > div > a {
  margin: auto;
}

.sponsor-bg .sponsor-bg__images > div > div {
  height: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
}

.sponsor-bg .sponsor-bg__title {
  color: #303235;
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
}

.sponsor-bg .sponsor-bg__subtitle {
  color: #474c55;
  font-family: 'Work Sans';
  font-size: 20px;
}

#eventsPage .subscribe-bottom {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.blogRowTitle {
  color: #303235;
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
}

.top-bg-img {
  background: url('./../../assets/about-bg.png');
  width: 100%;
  background-position: top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 100%;
  background-color: #ffffff;
}

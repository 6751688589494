@media only screen and (max-width: 1200px) {
  .sponsor-bg {
    background-position: center 0%;
  }
}

@media only screen and (max-width: 991px) {
  .sponsor-bg .sponsor-bg__desc {
    text-align: center;
  }

  #eventSponsor .sponsor__bg__button__col {
    text-align: center;
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 577px) {
  #eventSponsor {
    margin: 6em 0;
  }

  .sponsor-bg {
    padding: 9px 0px;
    background-size: 150%;
  }
}

@media only screen and (max-width: 400px) {
  #sponsor__sideKicker__logo {
    width: 100%;
    margin-top: 9%;
  }
}

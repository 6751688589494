@media only screen and (max-width: 991px) {
  #wsNavbar {
    padding: 0 !important;
  }

  #eventHeroHeader .header-text .text-div .hero-title {
    font-size: 35px;
    line-height: 35px;
  }

  #eventHeroHeader .header-text .text-div .subtitle,
  #eventHeroHeader .header-text .text-div .event {
    font-size: 18px;
    line-height: 25px;
  }

  #eventHeroHeader .header-text .btn {
    margin-left: 0 !important;
    margin-top: 1.5rem;
  }

  #eventHeroHeader .header-text {
    width: 100%;
  }

  #eventHeroHeader .text-div {
    padding: 1rem;
  }

  #eventHeroHeader .header-text .h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  #eventHeroHeader .header-text .date {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 375px) {
  #eventHeroHeader .header-text .text-div .hero-title {
    font-size: 30px;
    line-height: 30px;
  }
}

#eventHeroHeader .hero-div {
  height: 100vh;
  min-height: 700px;
  position: relative;
  overflow: hidden;
}
/* #eventHeroHeader .hero-div:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transform: skewY(-2deg);
  z-index: 3;
} */

.frame__top1 {
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 300px 100px 0;
  border-color: transparent rgba(136, 111, 216, 0.75) transparent transparent;
  z-index: 2;
}
.frame__top2 {
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 90px 300px 0;
  border-color: transparent rgba(106, 192, 223, 0.75) transparent transparent;
  z-index: 1;
}
.frame__bottom1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 150px 0 0 300px;
  border-color: transparent transparent transparent rgba(136, 111, 216, 0.75);
  /* transform: rotate(-2deg); */
  z-index: 2;
}
.frame__bottom2 {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 300px 0 0 100px;
  border-color: transparent transparent transparent rgba(106, 192, 223, 0.75);
  z-index: 1;
}

#eventHeroHeader .header-text .text-div .hero-title {
  font-size: 50px;
  font-weight: 600;
  line-height: 50px;
  color: #ffb21b;
}

#eventHeroHeader .header-text .text-div .hero-subtitle {
  color: #ffffff;
  font-size: 35px;
  font-weight: 500;
  line-height: 53px;
}

#eventHeroHeader .btn-header {
  color: #ffffff;
  font-family: Poppins;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  background-color: transparent;
  text-align: center;
  margin: auto;
  max-width: 180px;
  padding: 8px 10px;
  border: 1px solid #ffffff;
  border-radius: 23.5px;
  transition: 0.5s;
}

#eventHeroHeader .btn-header:hover {
  text-decoration: none;
  background: #ffffff;
  color: #4479d4;
}

#eventHeroHeader .header-text .text-div .hero-title.title-blue {
  color: #6ac0df;
}

#eventHeroHeader .header-text .text-div .subtitle {
  color: #fff;
  font-size: 21px;
  line-height: 31px;
  max-width: 600px;
  margin: auto;
}

#eventHeroHeader .header-text .text-div .event {
  color: #ffb21b;
  font-size: 21px;
  line-height: 31px;
}

#eventHeroHeader .header-text {
  z-index: 1;
  height: 100%;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}
#eventHeroHeader .header-text .date {
  font-size: 1.5rem;
  color: #6ac0df;
  font-weight: 500;
}
#eventHeroHeader .header-text .h2 {
  font-size: 2.2rem;
  color: #fff;
  line-height: 45px;
  font-weight: 600;
}
#eventHeroHeader .header-text .btn {
  border-color: white;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  padding: 0;
  height: 47px;
  width: 208px;
  text-align: center;
}
#eventHeroHeader .header-text .btn:hover,
#eventHeroHeader .header-text .btn:active,
#eventHeroHeader .header-text .btn:focus {
  color: #ffffff;
  background: transparent;
}
#eventHeroHeader .hero-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -100;
}
#eventHeroHeader .bg-filter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1920px;
  height: 100%;
  z-index: -99;
  background-color: rgba(42, 82, 150, 0.6);
}

#eventHeroHeader .bg-white {
  height: 100vh;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  #eventHeroHeader .header-text .text-div .hero-subtitle {
    font-size: 20px;
    line-height: 28px;
  }
  /* #eventHeroHeader .header-text .btn {
    margin-left: -5px !important;
  } */
}

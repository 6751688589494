.NotFoundContainer {
  height: 50vw;
  width: 100vw;
  position: absolute;
  background: #000;
  color: #fff;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.NotFoundContainer .error-title {
  font-size: 50px;
  font-weight: 600;
  margin: -1em 0 1em;
}

.NotFoundContainer .title {
  font-size: 112px;
  line-height: 134px;
  font-weight: bold;
}

.NotFoundContainer .title p {
  margin-bottom: 1rem;
}

.NotFoundContainer .title p span {
  background-color: #886fd8;
  padding: 0px 20px;
}

.NotFoundContainer .error-description {
  font-family: "Work Sans";
  font-size: 20px;
  margin-top: 2em;
  line-height: 15px;
}

.NotFoundContainer .error-cta-container {
  margin-top: 2em;
}

.NotFoundContainer .error-cta {
  color: #fff;
  background: transparent;
  border: 3px solid #fff;
  padding: 7px 24px;
  text-transform: uppercase;
  font-size: 27px;
  letter-spacing: 2px;
}

@media (max-width: 768px) {
  .categories-section .section-title {
    font-size: 1.3rem;
  }
  /* .category-cards {
    margin-bottom: 2rem
  } */
  .mt-5r {
    margin-top: 2rem;
  }
}
@media (max-width: 439.98px) {
  .arrow-long {
    width: 0.8rem;
  }
  .more-category-link {
    font-size: 11px;
    text-align: left;
  }
  .arrow-long.a-right .h-line {
    -webkit-transform: translateX(0.3rem);
    transform: translateX(0.3rem);
  } 
  .arrow-long i {
    padding: 2px;
  }

}
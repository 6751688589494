@media only screen and (max-width: 991px) {
  .about .about__title {
    font-size: 2em;
    line-height: 0.5em;
  }

  .about .about__desc {
    margin-top: 1em;
  }

  .about__conference__title {
    display: none;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .about .about__ticketTitle {
    font-size: 21px;
    line-height: 27px;
    margin-top: 15px;
  }

  .about .about__ticketDesc {
    font-size: 14px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
  .about .about__bg-ticket {
    margin: 2em auto;
    padding: 0;
  }

  .about .about__ticketTitle {
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
  }

  .about .about__ticketDesc {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .about__btn__992 {
    display: none;
  }

  .about__btn__768 {
    display: block;
  }

  .about .about__ticketBtn {
    width: 128px;
  }
}

@media only screen and (max-width: 991px) {
  .about .about__countdown span {
    font-size: 40px;
  }

  .about .about__countdown > div {
    max-width: 400px;
    margin: auto;
  }

  .about .about__bg-ticket {
    background-image: url('./../../assets/ticket-card.png');
    background-size: contain;
    min-height: 434px;
    min-width: 280px;
    padding: 67px 0px;
    margin: 2em auto;
  }

  .about .about__countdown .about__countdown__title p {
    max-width: 300px;
    margin: auto;
  }

  .about__bg-ticket .about__col {
    padding-left: 15px;
    text-align: center;
    max-width: 300px;
    margin: 10px auto 5px;
  }

  .about .about__count {
    font-size: 36px;
    line-height: 27px;
  }

  .about .about__countDesc {
    font-size: 14px;
    line-height: 21px;
  }

  .about .about__ticketTitle {
    font-size: 20px;
    line-height: 24px;
  }

  .about .about__ticketDesc {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 2em;
  }
}

@media only screen and (max-width: 375px) {
  .about .about__countdown p {
    font-size: 12px;
  }
}

.events__playlist {
  height: 430px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.events__playlist .thumbnail__title {
  color: #303235;
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
}

.events__playlist .thumbnail__title:hover {
  color: #4479d4;
}

.events__playlist .thumbnail__image {
  height: 92px;
  width: 164px;
  background-size: 168px 126px;
  background-position: center;
}

.events__playlist .events__list {
  width: 100%;
  margin: 0;
  padding: 0;
}

.events__playlist .thumbnail__subtitle {
  color: #9098a5;
  font-size: 13px;
  margin-top: -1rem;
}

.events__playlist .thumbnails__row {
  margin-bottom: 1rem;
  cursor: pointer;
}

.events__playlist .now__playing {
  padding-right: 1em;
}

.events__playlist .now__playing__hidden {
  display: none;
}

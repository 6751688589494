.title {
  /* color: #cad2de; */
  font-family: 'Work Sans';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.57px;
  line-height: 48px;
  text-align: center;
  height: auto;
  min-height: 100px;
  overflow: hidden;
  resize: none;
  border: none;
  width: 80%;
  margin: 0 auto 20px auto;
}

.row-title {
  margin-top: 40px;
}

.row-categories {
  margin: 0 auto 30px auto;
  width: 80%;
}

.subheading {
  font-family: 'Work Sans';
}

.img-container {
  background-size: cover;
  background-position: 80% 35%;
  width: auto;
  height: 450px;
}
#singlePostPage {
  padding-top: 1rem;
  padding-bottom: 5rem;
  position: relative;
}
#singlePostPage .top-opts .opt {
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  color: #9098a5;
  border: 1px solid #9098a5;
  padding: 0.4rem 1.3rem 0.4rem 1.13rem;
  border-radius: 3rem;
  font-size: 0.88rem;
}
#singlePostPage .top-opts .opt:hover {
  color: #4479d4;
  border-color: #4479d4;
  text-decoration: none;
}

#singlePostContent img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#singlePostContent .content-div {
  width: 80%;
  margin: 0 auto;
  line-height: 1.75;
  font-size: 1.125rem;
}
#singlePostContent h1,
#singlePostContent h2,
#singlePostContent h3,
#singlePostContent h4,
#singlePostContent h5,
#singlePostContent h6 {
  clear: both;
  line-height: 1.4;
  margin: 0 0 13.5px;
  padding: 27px 0 0;
}
/* #singlePostContent ul, #singlePostContent ol {
  margin: 0 0 24px;
  padding: 0;
} */
#singlePostContent p {
  margin-bottom: 24px;
}
#singlePostContent figure {
  width: 100% !important;
}
.scroll-top-div {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 2rem;
  right: 3rem;
  opacity: 0;
  background-color: #4479d4;
  height: 3rem;
  width: 3rem;
  border-radius: 5rem;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.scroll-to-top-btn {
  color: #fff;
  display: none;
  margin-bottom: 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  /* transform: unset; */
}
.scroll-top-div.show:active {
  opacity: 0.8;
}
.scroll-top-div.show:active .scroll-to-top-btn {
  transform: translateY(-0.2rem);
}
.scroll-top-div.show {
  opacity: 0.4;
  cursor: pointer;
}
.scroll-top-div.show .scroll-to-top-btn {
  display: block;
}
.scroll-top-div svg.mr-3 {
  margin-right: unset !important;
}

figure {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#authorDivider .divider {
  border-top: 1.5px solid #979797;
  display: block;
  width: 80%;
  margin: 0 auto;
}

#authorContent .container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

#authorContent .media img {
  max-width: 100%;
  height: auto;
  border-radius: 100%;
}

#authorContent .mediaName {
  font-weight: 700;
  font-family: 'Poppins';
}

#authorContent .authorLabel {
  color: #6e757a;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}

.relatedArticlelabel {
  color: #333333;
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.67px;
  text-transform: 'uppercase';
}

@media (max-width: 991.98px) {
  .title {
    font-size: 1.8rem;
    width: 90%;
    margin-bottom: 10px;
  }
  .row-categories {
    width: 100%;
    font-size: 0.9rem;
  }
  .img-container {
    height: 340px;
  }
  #authorContent .media-left {
    margin-bottom: 2rem;
  }
  #authorContent .mediaName {
    display: block;
    text-align: center;
  }
  #authorContent .container {
    flex-direction: column;
  }
  .scroll-top-div {
    right: 2rem;
  }
}
@media (max-width: 767.98px) {
  .title {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  .img-container {
    height: 340px;
  }
  #singlePostContent .content-div {
    width: 90%;
    font-size: 1rem;
  }
}
@media (max-width: 575.98px) {
  .title {
    font-size: 1.3rem;
    line-height: 1.5;
  }
  .img-container {
    height: 300px;
  }
}
@media (max-width: 439.98px) {
  .title {
    font-size: 1.1rem;
  }
  #singlePostContent .content-div {
    font-size: 0.9rem;
  }
  #authorContent .container {
    width: 90%;
    font-size: 0.9rem;
  }
  .img-container {
    height: 200px;
  }
}

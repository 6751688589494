#cookieStatementPage{
  padding-top: 6rem;
  padding-bottom: 6rem;
}
#cookieStatementPage p.h3 {
  font-weight: bold;
  color: #303235;
  letter-spacing: 0.5px;
}
#cookieStatementPage p.sub {
  font-size: 1.2rem;
  color: #9098A5;
  letter-spacing: 0.5px;
  font-weight: 400;
}
#cookieStatementPage .body-text {
  color: #3F4758;
  font-size: 1.2rem;
}
#cookieStatementPage .body-text p {
  margin-bottom: 2rem
}
#cookieStatementPage .body-text li {
  margin-bottom: 0.7rem
}
@media (max-width: 575.98px) {
  #cookieStatementPage {
    padding: 4rem 2rem;
  }
  #cookieStatementPage p.h3 {
    font-size: 1.3rem;
  }
  #cookieStatementPage p.sub {
    font-size: 1rem;
  }
  #cookieStatementPage .body-text {
    font-size: 1rem;
  }
  #cookieStatementPage a {
    word-break: break-all;
  }
}
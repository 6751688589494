.article-card {
  border: none;
  color: unset;
  text-decoration: none;
}

/* HOVER STATE */
.article-card:hover {
  color: unset;
  text-decoration: none;
}
.article-card:hover .go-up-hover {
  -webkit-transform: translatey(-1.7rem);
  transform: translatey(-1.7rem);
}
.article-card:hover .progress-bar {
  opacity: 1;
  width: 100%;
}
.article-card:hover .card-title-div {
  color: white;
}
.article-card:hover .card-title-div .card-title {
  color: white;
}
.article-card:hover .card-title-div .hover-bg-tt {
  background: #6ac0df;
}
.article-card:hover .card-title-div .hover-bg-ub {
  background: #4479d4;
}
.article-card:hover .card-title-div .hover-bg-rp {
  background: #886fd8;
}

.no-author .article-card:hover .card-img,
.no-category .article-card:hover .card-img {
  transform: scale(1.1);
}
.no-author .article-card:hover .card-img::before,
.no-category .article-card:hover .card-img::before {
  opacity: 1;
}
.long .article-card:hover .badge-div,
.long .article-card:hover .card-title-div {
  -webkit-transform: translatex(-2.5rem);
  transform: translatex(-2.5rem);
}

.article-card .go-up-hover {
  transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}

/* Featured Image */
.article-card .card-img-div {
  overflow: hidden;
}
.article-card .card-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: #cad2de;
  border-radius: 0;
  transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.normal .article-card .card-img:before {
  content: "";
  opacity: 0;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.article-card .card-body {
  padding: 0;
}
.article-card .badge-div {
  margin-bottom: 1rem;
}
.article-card .badge {
  white-space: initial;
  display: inline;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.article-card .card-title-div {
  margin-bottom: 0.5rem;
  /* display: inline; */
  /* transition: color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); */
}
.article-card .card-title {
  padding: 0rem 0.3rem;
  line-height: 1.56;
  /* display: inline; */
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  border-radius: 2px;
  transition: background 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.article-card .card-title-div span {
  display: none;
}
/* .article-card .card-title::after {
    content: "";
    text-align: right;
    position: absolute;
    top: 32.5px;
    right: -14rem;
    width: 2rem;
    height: 1.4em;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 50%);
  } */
.article-card .card-text {
  font-size: 1rem;
  line-height: 1.285;
  margin-bottom: 1.2rem;
  padding: 0rem 0.3rem;
}
.article-card .progress-bar {
  width: 0%;
  opacity: 0;
  -webkit-transform: translatey(-0.4rem);
  transform: translatey(-0.4rem);
  transition: width 0.1s cubic-bezier(0.39, 0.575, 0.565, 1),
    opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.article-card .author-bottom {
  padding: 0rem 0.3rem;
}
.article-card .author-bottom .media-middle {
  width: 38px;
  margin-right: 1rem;
  background-color: #DAE0E8;
  border-radius: 100%;
}
.article-card .author-bottom .media-middle img {
  max-width: 100%;
  height: auto;
  border-radius: 100%;
}
.article-card .author-bottom .media-body p {
  font-size: 0.876rem;
}

.article-card .card-options {
  display: none;
  align-items: center;
  width: 11rem;
}
.article-card .card-options .opt {
  transition: color 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  color: #9098a5;
}
.article-card .card-options .opt:hover {
  color: rgb(71, 76, 85);
  text-decoration: none;
}
.article-card .card-options .divider {
  height: 1rem;
  width: 0.08rem;
  margin: 0;
  background: #9098a5;
}
.article-card .for-seo {
  display: none;
}

/* Card Types */
.featured .article-card .card-img-div {
  width: 100%;
  height: 405px;
}

.featured .article-card .card-title {
  font-size: 1.75rem;
}

.featured .article-card .card-text {
  font-size: 1.25rem;
  padding: 0rem 0.3rem;
}

.featured .article-card .progress-bar {
  height: 0.4rem;
}

.normal .article-card {
  height: 37.7425rem;
}
.normal .article-card .card-body {
  position: relative;
}
.normal .article-card .go-up-hover {
  height: 15.485625rem
}
.normal .article-card .author-bottom {
  position: absolute;
  bottom: 0;
}
.normal .article-card .card-img-div {
  width: 100%;
  height: 21.9589rem;
  margin-bottom: 0.75rem
}

.normal .article-card .card-title,
.long .article-card .card-title,
.normal .article-card .psuedo-card-title {
  font-size: 1.3125rem;
}
/* .normal .article-card .card-title-div {
  min-height: 61px;
} */
.normal .article-card .progress-bar {
  height: 0.3rem;
}

.long .card-img-div {
  height: 153px;
  min-width: 272px;
  margin-bottom: 0 !important;
  margin-right: 1.8rem;
}
.long .article-card .card-title {
  margin-bottom: 1.5rem;
}
.long .article-card {
  display: flex;
  flex-direction: row;
  position: relative;
}
.long .progress-bar {
  position: absolute;
  bottom: 0;
  height: 0.3rem;
  -webkit-transform: unset;
  transform: unset;
}
.long .article-card .card-title-div {
  margin-bottom: 0.3rem;
}
.long .article-card .badge-div {
  margin-bottom: 0.5rem;
}
.long .article-card:hover .badge-div,
.long .article-card:hover .card-title-div {
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.long .article-card .progress-bar {
  transition: width 0.4s cubic-bezier(0.39, 0.575, 0.565, 1),
    opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.editable .article-card:hover .badge,
.editable .article-card:hover .card-title-div {
  transition: none;
  transform: unset;
}
.editable .article-card .progress-bar {
  display: none;
}
.editable .article-card:hover .card-title-div .card-title {
  color: rgb(71, 76, 85);
}
.editable .article-card:hover .card-title {
  background: none !important;
}
.editable .article-card .go-up-hover {
  margin-top: 0.75rem;
}
.editable .article-card .card-body {
  max-width: 37rem;
}
.editable .article-card:hover .card-options {
  display: flex;
}
.normal.no-preview.no-category .article-card .card-title-div {
  height: 4.3rem;
}
.normal.no-preview.no-category .article-card .author-bottom {
  position: unset;
}
.normal.no-preview.no-category .article-card {
  height: unset;
}
.normal.no-preview.no-category .article-card .go-up-hover {
  height: unset;
}

.no-category .article-card:hover .go-up-hover {
  -webkit-transform: unset;
  transform: unset;
}
.no-category .article-card .badge-div {
  display: none;
}

.no-category .article-card .progress-bar {
  margin-top: 1.5rem;
}

.no-preview .article-card:hover .go-up-hover {
  -webkit-transform: unset;
  transform: unset;
}
.no-preview .article-card .card-text {
  display: none !important;
}

.no-preview .article-card .progress-bar {
  margin-top: 1.5rem;
}

.no-author-img .author-bottom .media-left {
  display: none;
}
.no-author-name .author-bottom .name {
  display: none !important;
}
.no-progress-bar .article-card .progress-bar {
  display: none;
}
 .mdot {
  font-weight: 900;
  font-size: 1.3rem;
  line-height: inherit;
  vertical-align: middle;
 }

@media (max-width: 991.92px) {
  .normal .article-card .card-title, .long .article-card .card-title, .normal .article-card .psuedo-card-title, .long .card-title {
    font-size: 1.25rem;
    /* line-height: 0.8; */
  }
  .category-cards .normal .article-card .card-img-div {
    height: 15.9589rem;
  }
}
@media (max-width: 767.92px) {
  #hotAndTrending .long .card-title {
    margin-bottom: 0.5rem;
  }
  .normal .article-card .card-title, .long .article-card .card-title, .normal .article-card .psuedo-card-title, .long .card-title {
    font-size: 1.25rem;
  }
  .long .article-card {
    align-items: center;
  }
  .long .card-img-div {
    height: 124px;
    min-width: 221px;
    margin-right: 1.2rem;
  }
  .long .article-card .badge-div {
    margin-bottom: 0.3rem;
  }
  .long .article-card .card-title {
    line-height: 1.5;
  }
  .featured .article-card .card-title {
    font-size: 1.5rem;
    /* line-height: 1.4; */
  }
  .article-card .author-bottom .media-body .date-time{
    line-height: 0.8;
  }
}
@media (max-width: 576.98px) {
  .normal .article-card .card-title, .long .article-card .card-title, .normal .article-card .psuedo-card-title, .long .card-title {
    font-size: 1.125rem;
  }
  .featured .article-card .card-img-div {
    height: 304px;
  }
  .featured .article-card .card-title {
    font-size: 1.375rem;
    line-height: 1.4;
  }
  .featured .article-card .card-text {
    font-size: 1rem;
  }
  .featured .article-card .badge-div {
    margin-bottom: 1rem;
  }
  .long .card-img-div {
    height: 124px;
    min-width: 164px;
    margin-right: 1rem;
  }
}
@media (max-width: 439.98px) {
  .normal .article-card .card-title, .long .article-card .card-title, .normal .article-card .psuedo-card-title, .long .card-title {
    font-size: 0.875rem;
  }
  .article-card .badge {
    font-size: 0.75rem;
  }
  .long .card-img-div {
    height: 89px;
    min-width: 89px;
  }
  .long .card-img-div {
    margin-right: 0.5rem;
  }
  .article-card .author-bottom .media-body .date-time{
    font-size: 0.75rem;
  }
}
.subscribe-bottom {
  background-image: url('./../../assets/subscribe-bg-img.png');
  width: 100%;
  background-position: center;
  background-size: cover;
  height: 100%;
  padding: 170px 0px;
  margin-bottom: 90px;
}

.subscribe-bottom .subscribe-bottom__title {
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 38px;
  font-weight: 600;
  line-height: 55px;
  margin-bottom: 60px;
}

.subscribe-bottom .subscribe-bottom__subtitle {
  color: #ffffff;
  font-family: 'Work Sans';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
}

.subscribe-bottom .subscribe-bottom__contact {
  color: #ffffff;
  font-family: 'Work Sans';
  font-size: 22px;
  font-weight: 500;
  text-align: right;
}

.subscribe-bottom .subscribe-bottom__contact a {
  color: #ffb21b;
}

.subscribe-bottom .subscribe-btn {
  color: #ffffff;
  border: 1px solid #ffffff;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  padding: 0;
  height: 47px;
  width: 208px;
  text-align: center;
  background-color: transparent;
  background-image: none;
}

.subscribe-bottom .subscribe-btn:hover {
  color: #4479d4 !important;
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  font-family: 'Poppins';
  font-weight: 600;
}

.subscribe-bottom .color-primary {
  color: #4479d4;
}

.subscribe-bottom .color-secondary {
  color: #6ac0df;
}

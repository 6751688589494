#eventSponsor {
  margin-top: 60px;
  margin-bottom: 160px;
}

#eventSponsor .sponsor-bg__btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  padding: 0;
  height: 47px;
  width: 208px;
  text-align: center;
}
#eventSponsor .underline {
  border-bottom: 3px solid #4479d4;
  padding-bottom: 0.1rem;
}

#eventSponsor .sponsor__bg__button__col {
  text-align: center;
}

.sponsor-bg .sponsor-bg__images > div > div > a {
  width: 130px;
}

.sponsor-bg .sponsor-bg__images > div > div > a > img {
  width: 100%;
}

.sponsor-bg .sponsor-bg__images > div > div:first-child > a > img {
  transform: scale(1.8);
}

.sponsor-bg .sponsor-bg__images > div > div:nth-child(3) > a > img,
.sponsor-bg .sponsor-bg__images > div > div:last-child > a > img {
  transform: scale(1.4);
}

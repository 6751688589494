@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .youtube__player iframe {
    height: 354px;
    width: 629px;
  }

  .events__playlist {
    height: 354px;
  }

  .events__playlist .thumbnail__image {
    height: 88.71px;
    width: 133px;
    background-size: 167.7px 117px;
  }
}

@media only screen and (max-width: 991px) {
  .events__playlist {
    height: 177px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 1em;
  }

  .events__playlist .events__list {
    display: inline-flex;
  }

  .events__playlist .thumbnails__row {
    margin-bottom: 0;
    cursor: pointer;
    width: 227px;
  }

  .events__playlist .thumbnail__title {
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 767px) {
  .youtube__player iframe {
    height: 324px;
    width: 576px;
  }

  .events__playlist .thumbnails__row {
    width: 203px;
    margin-right: 1em;
  }

  .events__playlist .thumbnail__title {
    width: 168px;
  }
}

@media only screen and (max-width: 577px) {
  .youtube__player {
    text-align: center;
  }

  .youtube__player iframe {
    height: 180px;
    width: 320px;
  }
}

#categoryPage {
  padding-top: 3.5rem;
  padding-bottom: 6.5rem;
}
#categoryPage .header .logo {
  margin-bottom: 2rem;
}
#categoryPage .header .h3 {
  margin-bottom: 1.2rem;
}
#categoryPage .header .font-size-sh {
  width: 35rem;
  margin: 0 auto;
}
#categoryPage .header .fw-border-bottom {
  padding-top: 4rem;
  border-bottom: 1px solid #979797;
  display: block;
}

#categoryPage .normal.no-preview.no-category {
  margin-bottom: 3rem;
}
#categoryPage .pagination-btn {
  color: #333333;
  font-family: "Work Sans";
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
#categoryPage .pagination-btn.active {
  color: #000;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  #categoryPage .header .logo {
    width: 5.5rem;
  }
  #categoryPage .header .h3 {
    font-size: 1.5rem;
  }
  #categoryPage .header .fw-border-bottom {
    padding-top: 2rem;
  }
  #categoryPage .header .font-size-sh {
    width: 100%;
    font-size: 1rem;
  }
}
@media (max-width: 576px) {
  #categoryPage .header .h3 {
    font-size: 1.3rem;
  }
  #categoryPage .header .logo {
    width: 4rem;
  }
}

.row-title {
  margin-top: 40px;
  margin-bottom: 20px;
}

.input-title::placeholder {
  color: #cad2de;
  font-family: Poppins;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.57px;
  line-height: 48px;
  text-align: left;
}

.input-title {
  /* color: #cad2de; */
  font-family: Poppins;
  font-size: 42px;
  letter-spacing: 1px;
  line-height: 48px;
  text-align: left;
  height: auto;
  min-height: 70px;
  overflow: hidden;
  resize: none;
  border: none;
  border-bottom: 1px #cad2de solid;
}

.form-control:focus {
  box-shadow: none;
  border: none;
  border-bottom: 1px #cad2de solid;
}

.result-count{
	color: #333333;
	font-family: Poppins;
	font-size: 28px;
	font-weight: bold;
	letter-spacing: 0.5px;
  line-height: 42px;
  text-transform: uppercase;
}

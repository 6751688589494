.subscribe-container-footer {
  width: 100%;
  background-color: #4479d4;
  padding: 4.5rem 2.5rem;
}
.subscribe-container-footer .btn {
  background-color: #6AC0DF;
  font-size: 1rem;
  padding: 0.8rem 2.5rem;
}
.subscribe-container-footer .subscribe-btn:hover {
  background-color: rgba(106, 192, 223, .80) !important;
}
.subscribe-container-footer p:first-child {
  font-size: 1.75rem;
  letter-spacing: 0px;
}

.subscribe-container-footer p:nth-last-child(2) {
  font-size: 1.3125rem;
  letter-spacing: 0px;
}

.subscribe-container-footer .form-control{
  border-top-left-radius: 5.25rem;
  border-bottom-left-radius: 5.25rem;
  height: 100%;
  padding: 0.65rem 1.5rem;
  font-size: 1rem;
}
.subscribe-container-footer .form-control::placeholder {
  color: #CAD2DE;
}
.subscribe-container-footer .input-group {
  width: 27.81rem;
  flex-wrap: unset;
  align-items: unset;
}



.subscribe-container-footer  .input-div{
  position: relative;
}
.subscribe-container-footer  .input-div .error-marker {
  display: none;
  opacity: 0;
  position: absolute;
  font-size: 0.7rem;
  font-weight: bold;
  color: #EA0000;
  height: 1rem;
  width: 1rem;
  border: 2px solid #EA0000;
  border-radius: 5rem;
  text-align: center;
  
  vertical-align: middle;
  line-height: 1.1;
  right: 1rem;
  bottom: 0;
  top: 0;
  margin: auto;
  cursor: pointer;
  transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  
}
.subscribe-container-footer .danger .error-marker {
  opacity: 1;
  display: inline-block;
}

.subscribe-container-footer .tooltip {
  display: none;
  right: 0.1rem;
  transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.subscribe-container-footer .tooltip-inner {
  background-color: #303235;
}
.subscribe-container-footer .tooltip .arrow::before {
  border-bottom-color: #303235;
}
.subscribe-container-footer .tooltip .arrow {
  right: 1rem;
}
.subscribe-container-footer .show-tooltip .tooltip {
  opacity: 1;
  display: initial;
}


@media (max-width: 575.98px) { 
  .subscribe-container-footer .form-control {
    border-radius: 5.25rem;
    margin-bottom: 2rem;
  }
  .subscribe-container-footer .input-group {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .subscribe-container-footer .btn {
    border-radius: 5.25rem !important;
  }
  .subscribe-container-footer  .input-div .error-marker {
    bottom: 2rem;
    letter-spacing: -1px;
  }
  .subscribe-container-footer .tooltip {
    bottom: -11px;
    right: 1px;
  }  
}
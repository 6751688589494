@media only screen and (max-width: 1023px) {
  .navbar-nav > li:first-child,
  ul.navbar-nav > li:nth-child(-n + 4) {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .sponsor-bg .sponsor-bg__images > div > div:nth-child(1) > a > img,
  .sponsor-bg .sponsor-bg__images > div > div:nth-child(2) > a > img {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 577px) {
  .top-bg-img {
    padding: 0px;
  }
}

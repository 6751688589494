.subscribe-container-fw {
  width: 100%;
  background-color: #4479d4;
  padding: 2rem 2.5rem;
}
.subscribe-container-fw .btn {
  background-color: #6AC0DF;
  font-size: 1rem;
  padding: 0.8rem 1.5rem;
}
.subscribe-container-fw .subscribe-btn:hover {
  background-color: rgba(106, 192, 223, .80) !important;
}
.subscribe-container-fw p {
  font-size: 1.75rem;
  font-weight: 400;
  letter-spacing: 0px;
}
#subscriberModal .modal-content {
  border-radius: 1.5rem;
}
#subscriberModal .modal-header {
  padding: 2rem;
}
#subscriberModal .modal-body {
  padding: 2rem 7rem 2.5rem 7rem;
}
#subscriberModal .modal-body p {
  font-size: 1.2rem;
  text-align: center;
}
#subscriberModal .modal-footer {
  padding-bottom: 4rem;
}
#subscriberModal{
  max-width: 578px !important;
  width: 578px;
}
#subscriberModal input {
  border-radius: 5rem;
  padding: 1.5rem 1.5rem;
}
#subscriberModal button {
  padding: 0.5rem 1.5rem;
  letter-spacing: 0px;
}
#subscriberModal img {
  height: 55px;
  width: 224px;
  margin: 0 auto;
  display: block;
}

@media (max-width: 767.98px) {
  #subscriberModal {
    width: 436px;
    margin-left: auto;
    margin-right: auto
  }
  #subscriberModal .modal-header {
    padding: 2rem 2rem 1.1rem 2rem;
  }
  #subscriberModal .modal-body {
    padding: 0rem 3rem 2rem 3rem; 
  }
  #subscriberModal .modal-footer {
    padding-bottom: 1rem;
  }
}
@media (max-width: 575.98px) {
  #subscriberModal {
    width: unset;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  #subscriberModal .modal-body p {
    font-size: 1rem;
  }
  #subscriberModal .modal-body {
    padding: 0rem 3rem 1rem 3rem;
  }
  #subscriberModal img {
    height: auto;
    width: 170px;
  }
  #subscriberModal .modal-footer button {
    font-size: 0.875rem;
  }
  #subscriberModal input {
    padding: 1.3rem;
    font-size: 0.875rem;
  }
}
@media (max-width: 435.98px) { 
  #subscriberModal img {
    width: 150px;
  } 
  #subscriberModal .modal-body p {
    font-size: 0.9rem;
  }
  #subscriberModal .modal-body {
    padding: 0rem 2rem 1rem 2rem;
  }
  .subscribe-container-fw {
    justify-content: center !important;
    flex-direction: column;
  }
  .subscribe-container-fw p {
    font-size: 1rem;
    text-align: center;
  }
  .subscribe-container-fw .btn {
    font-size: 0.8rem;
    padding: 0.75rem 1rem;
    margin-top: 1rem;
  }
}
.aboutBg {
  background: url('./../../assets/about-whole-bg.png');
  width: 100%;
  background-position: top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 100%;
  padding: 30px 0px;
  background-color: #ffffff;
}

.about .about__highlight {
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 27px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 40px;
  border-radius: 3px;
  background-color: #4479d4;
  padding: 0.2rem 1rem;
  display: inline;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.about .about__desc p {
  color: #333333;
  font-family: 'Work Sans';
  font-size: 20px;
  line-height: 27px;
}

.about .about__desc {
  margin-left: 24px;
}

.about .bold {
  font-weight: 700;
}

.about .about__pillarHighlight {
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 30px;
  border-radius: '50%';
  background-color: #4479d4;
  padding: 0.2rem 0.7rem;
  display: inline;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

.about .about__pillarCircle {
  color: #ffffff;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 30px;
  background-color: #4479d4;
  border-radius: 50%;
  text-align: center;
  width: 30px;
}

.about .about__pillarList li {
  color: #9098a5;
  font-family: 'Work Sans';
  font-size: 20px;
  line-height: 35px;
}

.about .about__firstRow {
  margin-top: 100px;
}

.about .about__secondRow {
  margin-top: 130px;
}

.about .about__pillarsRow {
  margin-top: 100px;
  margin-bottom: 50px;
}

.about .about__pillarSecondRow {
  margin-top: 48px;
  margin-bottom: 48px;
}

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .about .about__firstRow {
    margin-top: 0px;
  }

  .about .about__secondRow {
    margin-top: 60px;
  }

  .about .about__desc {
    margin-left: 0px;
  }

  .aboutBg {
    background: none;
  }

  .about .aboutBg__firstRow {
    background: url('./../../assets/about-header-bg-mobile.png');
    background-position: center right;
    background-size: 50%;
    background-repeat: no-repeat;
  }

  .about .aboutBg__secondRow {
    background: url('./../../assets/about-shape-left-mobile.png');
    background-position: bottom left;
    background-size: 50%;
    background-repeat: no-repeat;
  }

  .about .aboutBg__thirdRow {
    background: url('./../../assets/about-shape-right-mobile.png');
    background-position: bottom right;
    background-size: 50%;
    background-repeat: no-repeat;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .about .about__pillarsRow {
    margin-top: 0px;
  }

  .about .about__pillarSecondRow {
    margin-top: 0px;
    margin-bottom: 24px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .about .about__highlight {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.43px;
    line-height: 35px;
  }

  .about .about__nominateBoxCol {
    padding: 0;
  }

  .about .aboutBg__firstRow {
    background: url('./../../assets/about-header-bg-mobile.png');
    background-position: bottom right;
    background-size: 75%;
    background-repeat: no-repeat;
  }

  .about .aboutBg__secondRow {
    background: url('./../../assets/about-shape-left-mobile.png');
    background-position: bottom left;
    background-size: 75%;
    background-repeat: no-repeat;
  }

  .about .aboutBg__thirdRow {
    background: url('./../../assets/about-shape-right-mobile.png');
    background-position: 100% 20%;
    background-size: 75%;
    background-repeat: no-repeat;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
}

/* .top-bg-img { */
/* padding-bottom: 70px; */
/* } */

.banner {
  background-color: #6ac0df;
  padding: 120px 0;
  margin-bottom: 100px;
}

.about__2018 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.about .about__title {
  color: #474c55;
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 50px;
}

.about .about__titleHighlight {
  color: #fff;
  font-family: 'Poppins';
  letter-spacing: 0.5px;
  line-height: 40px;
  border-radius: 3px;
  background-color: #4479d4;
  padding: 0.2rem 0.5rem;
  display: inline;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.about .about__titleHighlight__2 {
  color: #fff;
  font-family: 'Poppins';
  letter-spacing: 0.5px;
  line-height: 40px;
  border-radius: 3px;
  background-color: #886fd8;
  padding: 0.2rem 0.5rem;
  display: inline-block;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

@media only screen and (max-width: 991px) {
  .about .about__title {
    text-align: center;
  }

  .about .about__titleHighlight__2 {
    display: block;
    width: 200px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
  }
}

.about .about__desc {
  color: #fff;
  font-family: 'Work Sans';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin: 0;
}

.about .about__btn {
  color: #4479d4;
  border-color: #4479d4;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  padding: 0;
  height: 43px;
  width: 278px;
  text-align: center;
}

.about .about__btn:hover {
  color: #ffffff;
  border-color: #ffffff;
  background-color: #4479d4;
  font-family: 'Poppins';
  font-weight: 600;
}

.about .about__count {
  color: #474c55;
  font-family: 'Poppins';
  font-size: 60px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
}

.about .about__countDesc {
  color: #474c55;
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
}

.about .about__videoCounter {
  height: auto;
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.about .about__videoTitle {
  color: #303235;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 0;
}

.about .about__videoTime {
  color: #9098a5;
  font-family: 'Work Sans';
  font-size: 14px;
  line-height: 16px;
}

.about .about__conference__title {
  color: #9098a5;
  text-align: center;
  margin-top: 3rem;
  font-size: 18px;
  text-transform: uppercase;
}

.about__btn__768 {
  display: none;
}

.about__bg-ticket .about__col {
  padding-left: 80px;
}

@media only screen and (max-width: 577px) {
  .about .about__ticketDesc {
    max-width: 300px;
    margin: auto;
  }
}

#privacyPolicyPage{
  padding-top: 6rem;
  padding-bottom: 6rem;
}
#privacyPolicyPage p.h3 {
  font-weight: bold;
  color: #303235;
  letter-spacing: 0.5px;
}
#privacyPolicyPage p.sub {
  font-size: 1.2rem;
  color: #9098A5;
  letter-spacing: 0.5px;
  font-weight: 400;
}
#privacyPolicyPage .body-text {
  color: #3F4758;
  font-size: 1.2rem;
}
#privacyPolicyPage .body-text p.h4{
  font-weight: bold;
  color: #303235;
  letter-spacing: 0.5px;
}
#privacyPolicyPage .body-text p {
  margin-bottom: 2rem
}
#privacyPolicyPage .body-text li {
  margin-bottom: 0.7rem
}

@media (max-width: 575.98px) {
  #privacyPolicyPage {
    padding: 4rem 2rem;
  }
  #privacyPolicyPage p.h3 {
    font-size: 1.3rem;
  }
  #privacyPolicyPage p.sub {
    font-size: 1rem;
  }
  #privacyPolicyPage .body-text {
    font-size: 1rem;
  }
  #privacyPolicyPage a {
    word-break: break-all;
  }
}
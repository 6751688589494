@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .speakers .speakers__moreSpeakersBox {
    height: 76%;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .speakers .speakers__moreSpeakersBox {
    height: 69%;
  }
}

@media only screen and (max-width: 767px) {
  .speakers .speakers__moreSpeakersBox {
    min-height: 163px;
  }
}

@media only screen and (max-width: 575px) {
  .speakers .speakers__imgContainer img {
    opacity: 1;
    filter: saturate(1);
  }

  .speakers__title {
    line-height: 40px;
  }

  .speakers__subtitle {
    line-height: 30px;
  }

  .speakers .speakers__imgContainer {
    margin-bottom: 50px;
  }
  .speakers .speakers__imgContainer > .speakers__social > div > a:active {
    background-color: rgba(145, 145, 145, 0.5);
  }

  .speakers .speakers__imgContainer .speakers__social {
    left: 0;
    right: 0;
    bottom: -40px;
    top: initial;
  }

  .speakers .speakers__imgContainer > .speakers__social {
    display: block;
  }
  .speakers .speakers__imgContainer > .speakers__social > div > a {
    font-size: 18px;
    padding: 5px 8px;
    margin: 0px 5px;
    color: #868686;
    /* border-radius: 50%; */
    display: inline;
    background-color: transparent;
  }
}

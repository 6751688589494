.conference__banner {
  position: fixed;
  bottom: -1px;
  z-index: 9;
  width: 100%;
}

.conference__banner__hide {
  display: none;
}

.conference__banner p {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  padding: 0 5px;
  text-align: center;
  margin: 8px 0;
  display: inline;
}

.conference__banner .logo {
  text-align: right;
  display: block;
}

.conference__banner span {
  font-size: 14px;
  margin: 0;
  color: rgba(255, 255, 255, 0.651);
  padding: 0;
  font-weight: 400;
  vertical-align: top;
  text-transform: lowercase;
}

.conference__banner > div > div:first-child > div > a > svg {
  width: 200px;
  margin-right: 10px;
}

.conference__banner > div > div:first-child > div {
  background-color: #3e6ec1;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.conference__banner > div > div > div {
  background-color: #3e6ec1;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conference__banner > div > div:nth-child(1) > div::after,
.conference__banner > div > div:nth-child(2) > div::after {
  background-image: url('../../assets/ticket-end-blue.svg');
  transform: scaleX(-1);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  width: 15px;
}

.conference__banner > div > div:nth-child(2) > div::before,
.conference__banner > div > div:nth-child(4) > div::before {
  background-image: url('../../assets/ticket-end-blue.svg');
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  width: 15px;
}

.conference__banner > div > div:nth-child(2) > div > div {
  margin: 0 8px;
  position: relative;
  z-index: 1;
}

/* Ticket  */
.conference__banner > div > div:nth-child(3) > div {
  background-color: #ffb21b;
}

.conference__banner > div > div:nth-child(3) > div::before {
  background-image: url('../../assets/ticket-end-orange.svg');
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  width: 15px;
}

.conference__banner > div > div:nth-child(3) > div::after {
  background-image: url('../../assets/ticket-end-orange.svg');
  transform: scaleX(-1);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  width: 15px;
}

.conference__banner > div > div:nth-child(3) > div > p {
  display: block;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  text-transform: uppercase;
}

.conference__banner > div > div:nth-child(3) > div > p:hover {
  text-decoration: none;
}
.conference__banner > div > div:nth-child(3) > div:hover {
  cursor: pointer;
}

@media (max-width: 1199px) {
  .conference__banner > div > div:nth-child(4) > div {
    display: none;
  }
}

@media (max-width: 991px) {
  .conference__banner > div > div:nth-child(3) > div > p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .conference__banner > div > div > div > div:not(:first-child) > span {
    display: none;
  }
  .conference__banner > div > div > div > div:not(:first-child) > p {
    padding: 0;
  }
  .conference__banner
    > div
    > div
    > div
    > div:not(:first-child):not(:last-child)
    > p::after {
    content: ':';
    position: absolute;
    right: -12px;
  }
  .conference__banner > div > div:first-child > div > a > svg {
    z-index: 1;
    width: 85px;
    margin-left: 10px;
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .conference__banner > div > div:nth-child(3) > div > p {
    font-size: 16px;
    z-index: 1;
    margin-right: 12px;
  }
  .conference__banner > div > div:first-child > div > a > svg {
    width: 60px;
  }
}

@media (max-width: 320px) {
  .conference__banner > div > div:nth-child(2) > div > div > p {
    font-size: 20px;
  }
  .conference__banner > div > div:nth-child(2) > div > div > span {
    font-size: 12px;
    vertical-align: text-top;
  }
  .conference__banner > div > div:nth-child(2) > div > div:first-child {
    margin: 0;
  }
  .conference__banner > div > div:first-child > div > a > svg {
    width: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .subscribe-bottom .subscribe-bottom__title {
    font-size: 27px;
    line-height: 40px;
  }

  .subscribe-bottom .subscribe-bottom__subtitle {
    font-size: 24px;
  }

  .subscribe-bottom .subscribe-bottom__contact {
    text-align: left;
    font-size: 20px;
  }

  .subscribe-bottom .subscribe-bottom__cta {
    margin-top: 6em;
  }

  .subscribe-bottom .subscribe-btn {
    font-size: 20px;
  }
}

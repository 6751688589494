.agendas__table {
  margin-top: 30px;
}

.agendas__title {
  color: #303235;
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-top: 100px;
}

.agendas a > button {
  display: none;
}

.agendas__table h2 {
  margin: 0;
  padding: 15px 0 10px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bolder;
  color: #4e6ca5;
}

.agendas__time {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.agendas__time p {
  margin: 0;
}

.agendas__break {
  background-color: #4e6ca5;
  color: #fff;
  padding: 5px 20px;
  text-align: center;
  text-transform: uppercase;
}

.agendas__name {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.agendas__table .background {
  background-color: #f1f8fa;
}

.agendas__table .background.divide {
  border-top: 1px solid #4e6ca5;
}

.agendas__table .singles {
  padding: 15px 0;
  display: inline-block;
}

.agendas__table .singles p {
  margin: 0;
}

.agendas__name > div > p {
  margin: 0;
}

.agendas__name > div > p > span {
  display: block;
  /* color: #40aed5; */
  font-weight: 500;
}

.agendas__smalltag {
  display: none;
}

@media only screen and (max-width: 767px) {
  .agendas a > button {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    padding: 0;
    height: 47px;
    width: 208px;
    text-align: center;
    margin: 20px auto 0px;
    display: block;
  }

  .agendas__largetag {
    display: none;
  }

  .agendas__smalltag {
    display: block;
    text-transform: uppercase;
    color: #40aed5;
    font-size: 14px;
  }

  .agendas__table .singles {
    padding: 0 0 20px;
  }

  .agendas__table .agendas__time {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .agendas__table .agendas__time.agendas__break {
    padding: 5px 15px;
  }

  .agendas__table .agendas__time.agendas__break + .agendas__break {
    margin-left: -1px;
  }

  .agendas__table .background.divide {
    border: 0;
  }

  .agendas__break {
    margin-bottom: -1px;
    text-align: right;
  }

  .agendas__break.remove {
    display: none;
  }

  .agendas__table .background {
    background-color: transparent;
  }

  .background__time {
    background-color: #f1f8fa;
  }
}

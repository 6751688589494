#wsNavbar {
  padding: 0.7rem 0;
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

#wsNavbar .navbar-brand img {
  max-height: 40px;
  height: 40px;
  /* width: 224px; */
}
#wsNavbar .navbar-nav {
  position: relative;
}
#wsNavbar .nav-link {
  color: #303235;
  font-family: 'Work Sans', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 18px;
  transition: color 0.1s ease-out, font-weight 0.1s ease-out;
}
#wsNavbar .navbar-nav .nav-link:hover {
  color: #4479d4;
  font-weight: 600;
}
#wsNavbar .navbar-nav .nav-link:focus,
#wsNavbar .navbar-nav .nav-link:active {
  font-weight: 600;
}
#wsNavbar .nav-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
#wsNavbar .nav-item > span {
  cursor: pointer;
}
#wsNavbar .nav-text-link {
  min-width: 6rem;
}
#wsNavbar .blog-link {
  color: white;
}
#wsNavbar .blog-link:hover {
  text-decoration: none;
}
/* #wsNavbar .subscribe-btn {
  margin-left: 0.75rem;
  margin-right: 1.5rem;
} */
#wsNavbar .ws-predictive-search {
  margin-left: auto;
  position: relative;
}
#wsNavbar .ws-predictive-search input {
  width: 0;
  position: absolute;
  z-index: 2;
  right: 1.5rem;
  border-width: 0 0 2px 0;
  border-bottom: 1px solid #fff;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem;
  top: -5px;
  font-family: 'Work Sans', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 13px;
  color: #fff;
  visibility: hidden;
  background: transparent;
}
#wsNavbar .ws-predictive-search input:focus,
#wsNavbar .ws-predictive-search input:active {
  outline: none;
}
#wsNavbar .ws-predictive-search .search-icon:hover {
  cursor: pointer;
}
#wsNavbar .logged-in .ws-predictive-search.open-search input {
  width: 22rem;
}
#wsNavbar .ws-predictive-search.open-search input {
  width: 30rem;
  visibility: visible;
}
#wsNavbar .editor .ws-predictive-search.open-search input {
  width: 20rem;
  visibility: visible;
}
#wsNavbar .search-icon {
  display: block;
  padding: 0.5em;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

#wsNavbar .search-icon .circle {
  position: relative;
  display: block;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border-radius: 70px;
  margin-top: -0.5rem;
  border: 2px solid #fff;
  transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: 1;
}

#wsNavbar .search-icon .handle {
  position: absolute;
  background-color: #fff;
  top: 14px;
  left: 18px;
  width: 9px;
  height: 2px;
  display: block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  float: right;
  border-radius: 2px;
  transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
}
#wsNavbar .search-icon .handle:after {
  content: '';
  background-color: #fff;
  width: 9px;
  height: 2px;
  display: block;
  float: right;
  border-radius: 2px;
  transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}

#wsNavbar .top-nav-link a {
  color: #474c55;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 18px;
  /* transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955); */
}
#wsNavbar .top-nav-link a:hover {
  text-decoration: none;
  font-weight: bold;
  color: #4479d4;
}
#wsNavbar .open-search .search-icon .circle {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 30% 40%;
  transform-origin: 30% 40%;
  background: #fff;
  border-color: #fff;
}
#wsNavbar .open-search .search-icon .handle,
#wsNavbar .open-search .search-icon .handle:after {
  top: 15px;
  left: 15px;
  width: 18px;
}
#wsNavbar .open-search .search-icon .handle:after {
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
#wsNavbar .buy-tickets-btn {
  cursor: pointer;
}
.ws-scrolled-down {
  background: #fff;
  padding: 0.2rem 0;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.04);
}
.ws-scrolled-down .blog-link {
  color: #474c55 !important;
}
.ws-scrolled-down .search-icon .circle {
  border-color: #474c55 !important;
}
.ws-scrolled-down .search-icon .handle,
.ws-scrolled-down .search-icon .handle:after {
  background-color: #474c55 !important;
}
.ws-scrolled-down .ws-predictive-search input {
  color: #474c55 !important;
  border-bottom-color: #474c55 !important;
}
/* Categories */
#wsCategoriesNavbar nav {
  background-color: #f4f7fc;
  /* position: fixed; */
  width: 100%;
  overflow: hidden;
}
#wsCategoriesNavbar .ws-cat-nav-links {
  vertical-align: middle;
  width: 100%;
}
#wsCategoriesNavbar .ws-cat-nav-links ul {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
}
#wsCategoriesNavbar .ws-cat-nav-links ul::-webkit-scrollbar {
  display: none;
}
#wsCategoriesNavbar .ws-cat-nav-links ul li {
  cursor: pointer;
  text-align: center;
}
#wsCategoriesNavbar .ws-cat-nav-links ul li:nth-child(1) {
  margin-left: 0;
}
#wsCategoriesNavbar .ws-cat-nav-links ul li a {
  color: #303235;
  text-decoration: none;
  padding-top: 1.3rem;
  padding-bottom: 1.25rem;
  font-family: 'Work Sans', 'Helvetica Neue', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 18px;
  transition: all 0.1s ease-out;
  text-align: center;
  display: block;
  border-bottom: 3px solid transparent;
  text-transform: uppercase;
  white-space: nowrap;
}
#wsCategoriesNavbar .ws-cat-nav-links ul li:nth-child(1) a {
  margin-left: 0;
}
#wsCategoriesNavbar .ws-cat-nav-links ul li:nth-child(6) a {
  min-width: 9rem;
}
#wsCategoriesNavbar .ws-cat-nav-links ul li:nth-child(1) a {
  min-width: 13rem;
}
#wsCategoriesNavbar .ws-cat-nav-links ul li:nth-child(3) a {
  min-width: 8rem;
}
#wsCategoriesNavbar .ws-cat-nav-links ul li a:hover,
#wsCategoriesNavbar .ws-cat-nav-links ul li a.active {
  font-weight: 600;
  color: #4479d4;
  border-color: #4479d4;
}

#wsNavbar .user-avatar-div {
  display: inline;
  margin-right: 0.5rem;
}
#wsNavbar .user-avatar-div img {
  border-radius: 5rem;
  height: 40px;
  width: 40px;
}
#wsNavbar .col-12 {
  padding: 0;
}

/* Editor Nav */
#editorNav .navbar {
  box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 0.03);
}
#editorNav .ws-predictive-search {
  position: relative;
}
#editorNav .ws-predictive-search input {
  right: 2rem;
}
#editorNav .new-post-btn {
  letter-spacing: 0.01rem;
  font-size: 0.875rem;
  padding: 0.5rem 1.3rem;
}

#subscriberModal .modal-content {
  border-radius: 1.5rem;
}
#subscriberModal .modal-header {
  padding: 2rem;
}
#subscriberModal .modal-body {
  padding: 2rem 7rem 2.5rem 7rem;
}
#subscriberModal .modal-body p {
  font-size: 1.2rem;
  text-align: center;
}
#subscriberModal .modal-footer {
  padding-bottom: 4rem;
}
#subscriberModal {
  max-width: 578px !important;
  width: 578px;
}
#subscriberModal input {
  border-radius: 5rem;
  padding: 1.5rem 1.5rem;
}
#subscriberModal button {
  padding: 0.5rem 1.5rem;
  letter-spacing: 0px;
}
#subscriberModal img {
  height: 55px;
  width: 224px;
  margin: 0 auto;
  display: block;
}
#wsCategoriesNavbar .cat-scroller-col {
  padding: 0;
}
#wsCategoriesNavbar .cat-nav-scoller {
  border: none;
  background: transparent;
  height: 100%;
  width: 100%;
  text-align: center;
}
#wsCategoriesNavbar .scroll-left {
  opacity: 0.4;
}
#wsCategoriesNavbar .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 992px) {
  #wsNavbar .container {
    max-width: 900px;
  }
  #wsCategoriesNavbar .container {
    max-width: 900px;
  }
  #wsNavbar .col-12 {
    padding: 0.875rem 15px;
  }
  #wsCategoriesNavbar .ws-cat-nav-links ul li {
    margin-left: 1.25rem;
  }
  #wsCategoriesNavbar .ws-cat-nav-links ul li:nth-child(1) {
    margin-left: 0rem;
  }
  #wsCategoriesNavbar .ws-cat-nav-links ul li a {
    font-size: 0.75rem;
  }
  #wsCategoriesNavbar .ws-cat-nav-links ul li:nth-child(6) a {
    min-width: unset;
  }
  #wsCategoriesNavbar .ws-cat-nav-links ul li:nth-child(1) a {
    min-width: unset;
  }
  #wsCategoriesNavbar .ws-cat-nav-links ul li:nth-child(3) a {
    min-width: unset;
  }
}
@media (max-width: 768px) {
  header {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  #wsNavbar .container {
    max-width: 720px;
  }
  #wsCategoriesNavbar .container {
    max-width: 720px;
  }
  #wsNavbar .navbar-brand img {
    height: auto;
    width: 200px;
  }
}
@media (max-width: 576px) {
  #wsNavbar .container {
    max-width: 540px;
  }
  #wsCategoriesNavbar .container {
    max-width: 540px;
  }
  #wsNavbar .navbar-brand img {
    height: auto;
    width: 175px;
  }
}
@media (max-width: 435.98px) {
  #wsNavbar {
    padding: 0.2rem 0;
  }
  #wsNavbar .navbar-brand img {
    height: auto;
    width: 160px;
  }
  .subscribe-btn {
    font-size: 12px;
    padding: 0.75rem 1rem;
  }
}
@media (max-width: 380px) {
  #wsNavbar .navbar-brand img {
    height: auto;
    width: 120px;
  }
  .subscribe-btn {
    font-size: 12px;
    padding: 0.6rem 0.75rem;
  }
}

#footer {
  padding-bottom: 3rem;
}
#footer .fw-border-top {
  border-top: 1.5px solid #979797;
  display: block;
}
#footer .row:nth-of-type(2) .col {
  display: flex;
  align-items: center;
}
#footer .row:nth-of-type(2) p {
  color: #2d3031;
  font-family: "Work Sans";
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 19px;
}
#footer .row:nth-of-type(2) img {
  width: 64.62px;
  margin-top: -3px;
}
#footer .row:nth-of-type(3) .col {
  display: flex;
  align-items: center;
}
#footer .row:nth-of-type(3) p:nth-child(1) {
  color: #2d3031;
  font-family: "Work Sans";
  font-size: 16px;
  line-height: 19px;
}
#footer .row:nth-of-type(3) .col span {
  margin: 0 0.75rem;
  color: #2d3031;
  font-family: "Work Sans";
  font-size: 15px;
  line-height: 19px;
}
#footer .ws-footer-link {
  color: #2d3031;
  font-family: "Work Sans";
  font-size: 15px;
  line-height: 19px;
}
#footer .ws-footer-link:hover {
  text-decoration: none;
  color: #303235;
  font-weight: 500;
}
#footer .ws-footer-icon {
  color: #474c55;
  font-size: 25px;
  margin-right: 13px;
}
#footer .ws-footer-icon svg {
  display: inline-block;
  vertical-align: middle;
}

#footer .btn-primary {
  background-color: red;
}

@media (max-width: 1199.98px) {
  #footer .row:nth-of-type(3) p:nth-child(1) {
    font-size: 14px;
  }
  #footer .row:nth-of-type(3) .col span {
    margin: 0 0.5rem;
    font-size: 13px;
  }
  #footer .ws-footer-link {
    font-size: 14px;
  }
  #footer .ws-footer-icon {
    font-size: 17px;
  }
}

@media (max-width: 767.98px) {
  #footer .row:nth-of-type(2) .col {
    justify-content: center;
    margin-bottom: 1rem;
  }
  #footer .row:nth-of-type(3) .col {
    display: block;
    text-align: center;
  }
  #footer .row:nth-of-type(3) .col div {
    margin-bottom: 0.7rem;
    margin-right: 0 !important;
  }
} 
.padding-0 {
  padding-right: 0;
  padding-left: 0;
}

.testimonial .testimonial__bg__1 {
  background-color: #3e6ec1;
}

.testimonial .testimonial__bg__2 {
  background-color: #6ac0df;
}

.testimonial .testimonial__bg__3 {
  background-color: #886fd8;
}

.testimonial .testimonial__desc > div {
  padding: 20px 10px;
  height: 325px;
  display: table-cell;
  vertical-align: middle;
}

.testimonial .testimonial__desc p:first-child {
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  color: #fff;
}

.testimonial .testimonial__desc p:last-child {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

.testimonial .testimonial__img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 250px;
}

.testimonial .testimonial__img__1 {
  background-image: url('http://wsadmin.wpengine.com/wp-content/uploads/2019/03/ws-con-testimonial-1.jpg');
}
.testimonial .testimonial__img__2 {
  background-image: url('http://wsadmin.wpengine.com/wp-content/uploads/2019/03/ws-con-testimonial-2.jpg');
}
.testimonial .testimonial__img__3 {
  background-image: url('http://wsadmin.wpengine.com/wp-content/uploads/2019/03/ws-con-testimonial-3.jpg');
}
.testimonial .testimonial__img__4 {
  background-image: url('http://wsadmin.wpengine.com/wp-content/uploads/2019/03/ws-con-testimonial-4.jpg');
}

@media screen and (max-width: 991px) {
  .testimonial .testimonial__desc > div {
    padding: 20px 50px;
  }
}

/* 
.testimonials-desc{
    text-align: center;
    color: #474C55;
	font-family: "Work Sans";
	font-size: 24px;
	font-weight: 500;
}
.testimonials-desc .container {
    min-height: 440px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.testimonials-desc .row {
    width: 80%;
}
.testimonials-desc .border-top {
    height: 6px;
    width: 110px;
    background-color: #303235;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}
.testimonials-desc .body {
    position: relative;
    display: initial;
}
.testimonials-desc .body img {
    position: absolute;
    top: -45px;
    left: -20px;
}
.testimonial-1{
    background-image: url("./../../assets/testimonial-bg-1.png");
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height:100%;
}

.testimonial-2{
    background-image: url("./../../assets/testimonial-bg-2.png");
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height:100%;
}

.testimonial-3{
    background-image: url("./../../assets/testimonial-bg-3.png");
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height:100%;
} */

.subscribe-container {
  width: 100%;
  height: 40rem;
  background-color: #4479d4;
  padding: 0 3rem 4rem 3rem;
}
.subscribe-container .subscribe-btn {
  background-color: #6AC0DF;
  font-size: 1.1rem;
}
.subscribe-container .subscribe-btn:hover {
  background-color: rgba(106, 192, 223, .80) !important;
}
.subscribe-container p {
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  font-weight: 300;
}

#subscriberModal .modal-content {
  border-radius: 1.5rem;
}
#subscriberModal .modal-header {
  padding: 2rem;
}
#subscriberModal .modal-body {
  padding: 2rem 7rem 2.5rem 7rem;
}
#subscriberModal .modal-body p {
  font-size: 1.2rem;
  text-align: center;
}
#subscriberModal .modal-footer {
  padding-bottom: 4rem;
}
#subscriberModal{
  max-width: 578px !important;
  width: 578px;
}
#subscriberModal input {
  border-radius: 5rem;
  padding: 1.5rem 1.5rem;
}
#subscriberModal button {
  padding: 0.5rem 1.5rem;
  letter-spacing: 0px;
}
#subscriberModal img {
  height: 55px;
  width: 224px;
  margin: 0 auto;
  display: block;
}

#subscriberModal .input-div{
  position: relative;
}
#subscriberModal .input-div .error-marker {
  opacity: 0;
  position: absolute;
  font-size: 0.7rem;
  font-weight: bold;
  color: #EA0000;
  height: 1rem;
  width: 1rem;
  border: 2px solid #EA0000;
  border-radius: 5rem;
  text-align: center;
  vertical-align: middle;
  line-height: 1.1;
  right: 1rem;
  bottom: 0;
  top: 0;
  margin: auto;
  cursor: pointer;
  transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  display: none;
}
#subscriberModal .danger .error-marker {
  opacity: 1;
  display: inline-block;
}

#subscriberModal .tooltip {
  right: 0.1rem;
  display: none;
  transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
#subscriberModal .tooltip-inner {
  background-color: #303235;
}
#subscriberModal .tooltip .arrow::before {
  border-bottom-color: #303235;
}
#subscriberModal .tooltip .arrow {
  right: 1rem;
}
#subscriberModal .show-tooltip-name .tooltip, #subscriberModal .show-tooltip-email .tooltip  {
  opacity: 1;
  display: block;
}

@media (max-width: 991.98px) {
  .subscribe-container {
    height: 19rem;
  }
}
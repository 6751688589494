.home-body-container {
  padding-top: 7rem;
  padding-bottom: 6.5rem;
}

/* Category section */
.more-category-link {
  transition: color 0.3s ease-in;
}
.more-category-link .arrow-long .h-line {
  border-color: #2655a5;
}
.more-category-link  .arrow-long i {
  border-color: #2655a5;
}

/* ARROW */
.arrow-long.a-right {
  justify-content: flex-end;
}

.arrow-long {
  position: relative;
  width: 1.5rem;
  transition: all 0.3s ease-in;
  transform: translateY(-0.29rem);
}
.arrow-long.a-right .h-line {
  transform: translateX(0.5rem);
}

.arrow-long .h-line {
  border-bottom: 1px solid #3e6ec1;
  width: 0.9rem;
  top: 0.67rem;
}
.arrow-long .right {
  transform: rotate(-45deg);
}

.arrow-long i {
  border: solid #3e6ec1;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}



@media (max-width: 992px) {
  .home-body-container.container {
    max-width: 900px; 
  } 
}
@media (max-width: 768px) {
  .home-body-container {
    padding-top: 3rem;
    padding-bottom: 0;
  }
  .home-body-container.container {
    max-width: 720px; 
  } 
}
@media (max-width: 576px) {
  .home-body-container.container {
    max-width: 540px; 
  } 
  .mt-5r {
    margin-top: 0rem;
  }
}
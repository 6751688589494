#heroCarousel {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
#heroCarousel a:hover {
  text-decoration: none;
}
#heroCarousel .slick-slide {
  background-color: rgb(242, 242, 242);
}
#heroCarousel .slick-slide .hero-card {
    height: 500px !important;
    display: flex !important;
    align-items: center;
}
#heroCarousel .slick-slide .hero-card{
  margin-right: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80% 35%; 
}
#heroCarousel .slick-slide .hero-card p{
  color: white;
  /* background: #6AC0DF; */
  border-radius: 2px;
}
#heroCarousel .slick-slide .hero-category {
  text-transform: uppercase;
  font-size: 14px;	
  letter-spacing: 0.5px;	
  display: inline-block;
  padding: 0 0.4rem;
  font-family: "Work Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
#heroCarousel .slick-slide .hero-title {
  width: 40%;
}
#heroCarousel .slick-slide .hero-title p {
  padding: 0.2rem 1rem;
  line-height: 1.45;
  display: inline;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.76px;
}
#heroCarousel .slick-dots .slider-active button:before {
  color: #fff;
}
#heroCarousel .slick-dots li button:before {
  color: #fff;
  opacity: 90%;
  font-size: 20px;
}
#heroCarousel .slick-dots {
  bottom: 55px;
}
#heroCarousel .slick-dots li {
  width: 15px;
}
#heroCarousel .slick-arrow {
  z-index: 2;
}
#heroCarousel .slick-prev {
  left: 5rem;
}
#heroCarousel .slick-next {
  right: 5rem;
}
#heroCarousel .slick-prev:before {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
#heroCarousel .slick-next:before {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
#heroCarousel .slick-prev:before, #heroCarousel .slick-next:before {
  content: '';
  opacity: 1;
  border: solid #ffffff;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
}
#heroCarousel .dotdotdot {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.76px;
  color: white !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

@media (max-width: 1200px) {
  #heroCarousel .slick-prev {
    left: 2rem;
  }
  #heroCarousel .slick-next {
    right: 2rem;
  }
}
@media (max-width: 992px) {
  #heroCarousel .slick-slide .hero-title {
    width: 65%;
  }
  #heroCarousel .slick-prev {
    left: 2rem;
  }
  #heroCarousel .slick-next {
    right: 2rem;
  }
  #heroCarousel .container {
    max-width: 900px; 
  } 
  #heroCarousel .hero-text  {
      width: 80%;
      margin: 0 auto;
  }
  #heroCarousel .slick-slide .hero-card {
    height: 338px !important;
  }
  #heroCarousel .slick-dots {
    bottom: 21px;
}
}
@media (max-width: 768px) {
  #heroCarousel .slick-slide .hero-title p {
    font-size: 1.5rem;
  }
  #heroCarousel .slick-slide .hero-card {
    height: 315px !important;
  }
  #heroCarousel .slick-dots {
    bottom: 20px;
  }
}
@media (max-width:576px) {
  #heroCarousel {
    display: none;
  }
}